import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact Us</h1>
    <div
          className="m-6 align-self-center text-gray-900"
        >
          <a href="tel:315-938-5234">
            <div className="flex mt-2">
              <div className="text-gray-900">
              315-938-5234<br />
              </div>
              <div>
                &nbsp;<sup><FontAwesomeIcon icon={faExternalLinkAlt} className="text-gray-900" /></sup>
              </div>
            </div>
          </a>
          <a href="https://goo.gl/maps/Xfg7t2VHmSEC5ooRA" target="_blank" rel="noopener noreferrer">
            <div className="flex text-gray-900 mt-4">
              <div className="">
              8405 CORNELL ROAD< br/>
              HENDERSON HARBOR, NY 13650
              </div>
              <div>
                &nbsp;<sup><FontAwesomeIcon icon={faExternalLinkAlt} className="text-gray-900" /></sup>
              </div>
            </div>
          </a>
        </div>
  </Layout>
)

export default ContactPage
